@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Nanum Gothic", sans-serif -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: linear-gradient(
    to left bottom,
    #fbe9ff,
    #faedff,
    #faf2ff,
    #fbf6ff,
    #fcfaff,
    #f9fcff,
    #f8feff,
    #f8ffff,
    #f1fffd,
    #edfff6,
    #f0ffec,
    #f8ffe0
  );
}
